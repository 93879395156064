import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function AdvanceFilter({ open, setOpen }) {
  const [zona, setZona] = useState("0");
  const [district, setDistrict] = useState("0");
  const [county, setCounty] = useState("0");
  const [propertyType, setPropertyType] = useState("0");
  const [typology, setTypology] = useState("0");
  const [objective, setObjective] = useState("0");
  const [condition, setCondition] = useState("0");
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);

  const { t, i18n } = useTranslation();

  return (
    <div
      className="_collapseAdvanceFilter"
      style={{
        minHeight: "200px",
        backgroundColor: "#F5F5F5",
      }}
    >
      <h2 className="text-center pt-2">{t("advance_title")}</h2>
      <form className=" m-4">
        <div className="d-flex flex-fill flex-wrap justify-content-around">
          <div
            className="form-group flex-fill m-0 mx-2"
            style={{ width: "300px" }}
          >
            <label className="m-0">{t("minimum_price")}</label>
            <input
              type="number"
              className="form-control m-0"
              id="minprice"
              placeholder={t("minimum_price_placeholder")}
              value={minPrice}
              onChange={(e) => {
                setMinPrice(e.target.value);
              }}
              autocomplete="off"
            />
          </div>
          <div
            className="form-group flex-fill m-0 mx-2"
            style={{ width: "300px" }}
          >
            <label className=" m-0">{t("maximum_price")}</label>
            <input
              type="number"
              className="form-control m-0"
              id="maxprice"
              placeholder={t("maximum_price_placeholder")}
              value={maxPrice}
              onChange={(e) => {
                setMaxPrice(e.target.value);
              }}
              autocomplete="off"
            />
          </div>
          <div
            className="form-group flex-fill m-0 mx-2"
            style={{ width: "300px" }}
          >
            <label className=" m-0">{t("county")}</label>
            <select
              value={county}
              onChange={(e) => {
                setCounty(e.target.value);
              }}
              className="custom-select"
            >
              <option value="0">{t("county_other")}</option>
              <option value="1">{t("county_coimbra")}</option>
              <option value="2">{t("county_lousa")}</option>
              <option value="3">{t("county_miranda")}</option>
              <option value="4">{t("county_gois")}</option>
              <option value="5">{t("county_poiares")}</option>
              <option value="6">{t("county_arganil")}</option>
              <option value="7">{t("county_oliveira_do_hospital")}</option>
            </select>
          </div>
          <div
            className="form-group flex-fill m-0 mx-2"
            style={{ width: "300px" }}
          >
            <label className=" m-0">{t("district")}</label>
            <select
              value={district}
              onChange={(e) => {
                setDistrict(e.target.value);
              }}
              className="custom-select"
            >
              <option value="0">{t("district_other")}</option>
              <option value="1">{t("district_coimbra")}</option>
            </select>
          </div>
          <div
            className="form-group flex-fill m-0 mx-2"
            style={{ width: "300px" }}
          >
            <label className=" m-0">{t("type_label")}</label>
            <select
              value={propertyType}
              onChange={(e) => {
                setPropertyType(e.target.value);
              }}
              className="custom-select"
            >
              <option value="0">{t("type_title")}</option>
              <option value="1">{t("type_apartment")}</option>
              <option value="2">{t("type_office")}</option>
              <option value="3">{t("type_commercial_property")}</option>
              <option value="4">{t("type_store")}</option>
              <option value="5">{t("type_habitation")}</option>
              <option value="6">{t("type_building")}</option>
              <option value="7">{t("type_room")}</option>
              <option value="8">{t("type_farms_and_estates")}</option>
              <option value="9">{t("type_land")}</option>
            </select>
          </div>

          <div
            className="form-group flex-fill m-0 mx-2"
            style={{ width: "300px" }}
          >
            <label className=" m-0">{t("room_label")}</label>
            <select
              value={typology}
              onChange={(e) => {
                setTypology(e.target.value);
              }}
              className="custom-select"
            >
              <option value="0">{t("room_title")}</option>
              <option value="1">{t("room_0")}</option>
              <option value="2">{t("room_1")}</option>
              <option value="3">{t("room_2")}</option>
              <option value="4">{t("room_3")}</option>
              <option value="5">{t("room_4")}</option>
              <option value="6">{t("room_5")}</option>
              <option value="7">{t("room_6")}</option>
              <option value="8">{t("room_7")}</option>
              <option value="9">{t("room_8")}</option>
              <option value="10">{t("room_9")}</option>
              <option value="11">{t("room_10")}</option>
            </select>
          </div>
          <div
            className="form-group flex-fill m-0 mx-2"
            style={{ width: "300px" }}
          >
            <label className=" m-0">{t("objective_label")}</label>
            <select
              value={objective}
              onChange={(e) => {
                setObjective(e.target.value);
              }}
              className="custom-select"
            >
              <option value="0">{t("objective_title")}</option>
              <option value="1">{t("objective_sell")}</option>
              <option value="2">{t("objective_rent")}</option>
              <option value="3">{t("objective_trespass")}</option>
            </select>
          </div>
          <div
            className="form-group flex-fill m-0 mx-2"
            style={{ width: "300px" }}
          >
            <label className=" m-0">{t("state_label")}</label>
            <select
              value={condition}
              onChange={(e) => {
                setCondition(e.target.value);
              }}
              className="custom-select"
            >
              <option value="0">{t("state_title")}</option>
              <option value="1">{t("state_new")}</option>
              <option value="2">{t("state_used")}</option>
              <option value="3">{t("state_under_construction")}</option>
              <option value="4">{t("state_to_remodel")}</option>
              <option value="5">{t("state_refurbished")}</option>
            </select>
          </div>
          <div
            className="form-group flex-fill m-0 mx-2"
            style={{ height: "1px", width: "300px" }}
          ></div>
          <div
            className="form-group flex-fill m-0 mx-2"
            style={{ height: "1px", width: "300px" }}
          ></div>
          <div
            className="form-group flex-fill m-0 mx-2"
            style={{ height: "1px", width: "300px" }}
          ></div>
          <div
            className="form-group flex-fill m-0 mx-2"
            style={{ height: "1px", width: "300px" }}
          ></div>
        </div>

        <div className="d-flex justify-content-center pb-4">
          <Button
            onClick={() => setOpen(false)}
            aria-controls="example-collapse-text"
            aria-expanded={open}
            className="btn btn-dark mx-2"
            style={{ minWidth: "100px" }}
          >
            {t("close")}
          </Button>
          <Link
            to={{
              pathname: "/filter",
              state: {
                zona: zona,
                district: district,
                county: county,
                objective: objective,
                propertyType: propertyType,
                typology: typology,
                condition: condition,
                minPrice: minPrice,
                maxPrice: maxPrice,
              },
            }}
            className=" mx-2"
          >
            <button
              className="btn btn-dark h-100 w-100"
              style={{ minWidth: "100px" }}
            >
              {t("search")}
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
}

export default AdvanceFilter;
