import React, { useState } from "react";
import emailjs from "emailjs-com";

function DetailsHouseEmail({ houseRef }) {
  const [erroCampos, setErroCampos] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    let nameVal = e.target.elements[1].value;
    let emailVal = e.target.elements[2].value;
    let messageVal = e.target.elements[3].value;

    if (nameVal == "" || emailVal == "" || messageVal == "") {
      setErroCampos(true);
    } else {
      setErroCampos(false);
      emailjs
        .sendForm(
          "gmail",
          "template_wmllx08",
          e.target,
          "user_8r8HaYp7bty5nBTb6qvuj"
        )
        .then(
          (result) => {},
          (error) => {
            console.log("erro enviar email");
            console.log(error.text);
          }
        );
      e.target.reset();
    }
  };
  return (
    <>
      <h2 className="">Interessado no Imovél?</h2>
      <form onSubmit={sendEmail}>
        <div className="mx-auto">
          <div className="form-group d-none">
            <input
              type="text"
              className="form-control"
              placeholder="Nome..."
              value={houseRef}
              name="ref"
              disable
            />
          </div>
          <div className="form-group ">
            <input
              type="text"
              className="form-control"
              placeholder="Nome..."
              name="nome"
            />
          </div>
          <div className="form-group pt-0">
            <input
              type="email"
              className="form-control"
              placeholder="Email..."
              name="email"
            />
          </div>
          <div className="form-group pt-0">
            <textarea
              className="form-control"
              id="mensagem"
              cols="30"
              rows="8"
              placeholder="Mensagem..."
              name="mensagem"
            ></textarea>
          </div>
          {erroCampos && (
            <p className="text-center alert alert-warning">
              Preencha todos os campos
            </p>
          )}
          <div className="d-flex justify-content-center">
            <input
              type="submit"
              className="btn btn-dark"
              value="Enviar Email"
            />
          </div>
        </div>
      </form>
      {/* <div className="container pt-4 p-0" style={{maxHeight:"100px"}}>
                <h2 className="col-12 mx-auto">Interessado no Imovél?</h2>
                <form onSubmit={sendEmail}>
                    <div className="row mx-auto">
                        <div className="col-12 form-group mx-auto d-none" >
                            <input type="text" className="form-control" placeholder="Nome..." value={houseRef} name="ref" disable/>
                        </div>
                        <div className="col-12 form-group mx-auto">
                            <input type="text" className="form-control" placeholder="Nome..." name="nome"/>
                        </div>
                        <div className="col-12 form-group mx-auto pt-0">
                            <input type="email" className="form-control" placeholder="Email..." name="email"/>
                        </div>
                        <div className="col-12 form-group mx-auto pt-0">
                            <textarea className="form-control" id ="mensagem" cols="30" rows="8" placeholder="Mensagem..."name="mensagem"></textarea>
                        </div>
                        <div className="col-12 mx-auto pt-0">
                            <input type="submit" className="btn btn-dark" value="Enviar Email"/>
                        </div>
                    </div>
                </form>
            </div> */}
    </>
  );
}

export default DetailsHouseEmail;
