import React, { useState, useEffect } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import firebase from "./../../../firebase/index";
import ImageUploading from "react-images-uploading";
import { BounceLoader } from "react-spinners";
import Switch from "react-switch";

const db = firebase.firebase.firestore();
const storage = firebase.firebase.storage();

function PageAdmin({ firebase, houseRef, handleChoseHouse }) {
  const [images, setImages] = useState([]);
  //let [imagesURLs, setImagesURLs] = useState([]);
  const [docRef, setDocRef] = useState();
  const [docRefDescription, setDocRefDescription] = useState();
  const maxNumber = 100;
  const emptyHouse = {
    ref: "",
    images: [],
  };
  //const [ref, setRef] = useState("");
  //const [imgToSave, setImgToSave] = useState([]);
  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [priceOld, setPriceOld] = useState(0);
  const [description, setDescription] = useState("");
  const [country, setCountry] = useState("0");
  const [county, setCounty] = useState("0");
  const [district, setDistrict] = useState("0");
  const [objective, setObjective] = useState("0");
  const [propertyType, setPropertyType] = useState("0");
  const [typology, setTypology] = useState("0");
  const [condition, setCondition] = useState("0");
  const [yearConstruction, setYearConstruction] = useState("");
  const [activeToggle, setActiveToggle] = useState(true);
  const [highlightToggle, setHighlightToggle] = useState(false);
  const [reservedToggle, setReservedToggle] = useState(false);
  const [soldToggle, setSoldToggle] = useState(false);

  const [afterClickSave, setAfterClickSave] = useState(false);
  const [saved, setSaved] = useState(true);
  let [addHouse, setAddHouse] = useState(emptyHouse);
  //const [file, setFile] = useState(null);

  useEffect(() => {
    if (houseRef == "") {
      setAddHouse(emptyHouse);

      setImages([]);
      setName("");
      setPrice(0);
      setPriceOld(0);
      setDescription("");
      setCountry("0");
      setCounty("0");
      setDistrict("0");
      setObjective("0");
      setPropertyType("0");
      setTypology("0");
      setCondition("0");
      setYearConstruction("");
    } else {
      getHouse();
    }
  }, [houseRef]);

  const getHouse = () => {
    firebase.db
      .collection("housesDescription")
      .where("ref", "==", houseRef)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setDocRef(doc.id);
          //setAddHouse(doc.data());
          setImages(doc.data()["images"]);
          setName(doc.data()["name"]);
          setPrice(doc.data()["price"]);
          setPriceOld(doc.data()["price_old"]);
          setDescription(doc.data()["description"]);
          setCountry(doc.data()["country"]);
          setCounty(doc.data()["county"]);
          setDistrict(doc.data()["district"]);
          setObjective(doc.data()["objective"]);
          setPropertyType(doc.data()["property_type"]);
          setTypology(doc.data()["typology"]);
          setCondition(doc.data()["condition"]);
          setYearConstruction(doc.data()["year_construction"]);
          setActiveToggle(doc.data()["active"]);
          setHighlightToggle(doc.data()["highlight"]);
          setReservedToggle(doc.data()["reserved"]);
          setSoldToggle(doc.data()["sold"]);
        });
      })
      .catch((err) => {
        console.log(err.message);
      });

    firebase.db
      .collection("houses")
      .where("ref", "==", houseRef)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setDocRefDescription(doc.id);
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const onNewImageChange = (imageList, addUpdateIndex) => {
    // data for submit
    //console.log(imageList, addUpdateIndex);

    setImages(imageList);
  };

  // const handleChange = (e) => {
  //   const { id, value } = e.target;
  //   setAddHouse((prevState) => ({
  //     ...prevState,
  //     [id]: value,
  //   }));
  // };
  const handleAddHouse = async () => {
    if (images.length > 0) {
      const storageRef = storage.ref();
      setAfterClickSave(true);

      const promises = images.map(async (image, index) => {
        await storageRef
          .child(image.file.name.replace(/[^a-zA-Z0-9]/g, ""))
          .put(image.file)
          .then(async (snapshot) => {
            await snapshot.ref.getDownloadURL().then((downloadURL) => {
              addHouse.images = [
                ...addHouse.images,
                {
                  name: image.file.name.replace(/[^a-zA-Z0-9]/g, ""),
                  url: downloadURL,
                  orderId: index,
                },
              ];
            });
          });
      });
      await Promise.all(promises)
        .then(async () => {
          await firebase.db
            .collection("globalData")
            .doc("idNum")
            .get()
            .then((doc) => {
              addHouse.ref = doc.data()["id"].toString();
            })
            .catch((err) => {
              console.log(err.message);
            });
          await firebase.db
            .collection("globalData")
            .doc("idNum")
            .set({ id: Number(addHouse.ref) + 1 })
            .catch((err) => {
              console.log(err.message);
            });
        })
        .then(async () => {
          await firebase.db
            .collection("housesDescription")
            .add({
              condition: condition || "0",
              country: country || "0",
              county: county || "0",
              description: description || "",
              district: district || "0",
              images: addHouse.images,
              name: name || "",
              objective: objective || "0",
              price: parseInt(price) || 0,
              price_old: parseInt(priceOld) || 0,
              property_type: propertyType || "0",
              ref: addHouse.ref,
              typology: typology || "0",
              year_construction: yearConstruction || "",
              active: activeToggle || false,
              highlight: highlightToggle || false,
              reserved: reservedToggle || false,
              sold: soldToggle || false,
            })
            .then((documentReference) => {
              //console.log("document reference ID", documentReference.id);
              firebase.db
                .collection("houses")
                .add({
                  name: name || "",
                  price: parseInt(price) || 0,
                  price_old: parseInt(priceOld) || 0,
                  description: description || "",
                  ref: addHouse.ref,
                  image: addHouse.images.find((img) => img.orderId == 0),
                  district: district,
                  county: county,
                  active: activeToggle || false,
                  highlight: highlightToggle || false,
                  reserved: reservedToggle || false,
                  sold: soldToggle || false,
                })
                .then((documentReference) => {
                  setAfterClickSave(false);
                  setSaved(false);
                })
                .catch((error) => {
                  console.log(error.message);
                });
            })
            .catch((error) => {
              console.log(error.message);
            });
        });
    }
  };

  const handleAddHouseUpdate = async () => {
    if (images.length > 0) {
      const storageRef = storage.ref();
      setAfterClickSave(true);
      addHouse.images = [];

      const promises = images.map(async (image, index) => {
        if (image["url"]) {
          addHouse.images = [
            ...addHouse.images,
            {
              name: image["name"],
              url: image["url"],
              orderId: index,
            },
          ];
        } else {
          await storageRef
            .child(image.file.name)
            .put(image.file)
            .then(async (snapshot) => {
              await snapshot.ref.getDownloadURL().then((downloadURL) => {
                addHouse.images = [
                  ...addHouse.images,
                  {
                    name: image.file.name,
                    url: downloadURL,
                    orderId: index,
                  },
                ];
              });
            });
        }
      });

      await Promise.all(promises).then(async () => {
        await firebase.db
          .collection("housesDescription")
          .doc(docRef)
          .update({
            condition: condition || "0",
            country: country || "0",
            county: county || "0",
            description: description || "",
            district: district || "0",
            images: addHouse.images,
            name: name || "",
            objective: objective || "0",
            price: parseInt(price) || 0,
            price_old: parseInt(priceOld) || 0,
            property_type: propertyType || "0",
            typology: typology || "0",
            year_construction: yearConstruction || "",
            active: activeToggle || false,
            highlight: highlightToggle || false,
            reserved: reservedToggle || false,
            sold: soldToggle || false,
          })
          .then(() => {})
          .catch(() => {
            console.log("erro no update");
          });

        await firebase.db
          .collection("houses")
          .doc(docRefDescription)
          .update({
            name: name,
            price: price,
            price_old: parseInt(priceOld) || 0,
            description: description,
            district: district,
            county: county,
            image: addHouse.images.find((img) => img.orderId == 0),
            active: activeToggle || false,
            highlight: highlightToggle || false,
            reserved: reservedToggle || false,
            sold: soldToggle || false,
          })
          .then(() => {
            setAfterClickSave(false);
            setSaved(false);
          })
          .catch(() => {
            console.log("erro no update");
          });
      });
    }
  };

  const handleAddNewHouse = () => {
    handleChoseHouse("");

    setAddHouse(emptyHouse);

    setImages([]);
    setName("");
    setPrice(0);
    setPriceOld(0);
    setDescription("");
    setCountry("0");
    setCounty("0");
    setDistrict("0");
    setObjective("0");
    setPropertyType("0");
    setTypology("0");
    setCondition("0");
    setYearConstruction("");

    setSaved(true);
  };

  const singOut = () => {
    firebase.auth
      .signOut()
      .then(function () {
        // Sign-out successful.
      })
      .catch(function (error) {
        // An error happened.
      });
  };
  // const onImageChange = (e) => {
  //   const img = e.target.files[0];
  //   setFile(img);
  // };
  return (
    <>
      {afterClickSave === true && (
        <div className="d-flex flex-column align-items-center justify-content-center m-auto h-100 pt-5">
          <BounceLoader loading size={75} />
          <p className="pt-2">A guardar o imovel...</p>
        </div>
      )}
      {saved === false && (
        <div
          className="d-flex flex-column m-auto justify-content-center align-items-center"
          style={{ paddingBottom: "150px" }}
        >
          <h2>Casa Guardada</h2>
          <button
            style={{ width: "200px" }}
            type="submit"
            className="btn btn-secondary"
            onClick={(e) => {
              e.preventDefault();
              handleAddNewHouse();
            }}
          >
            Criar Nova
          </button>
        </div>
      )}
      {saved === true && afterClickSave == false && (
        <div>
          <div className="d-flex flex-column m-auto" style={{ width: "82vw" }}>
            <div className=" p-5">
              <h2 className="m-auto" style={{ textAlign: "center" }}>
                {houseRef ? "Editar Casa - " + houseRef : "Nova Casa"}
              </h2>
              <form className="">
                <div className="form-group">
                  <label className="">Nome:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Nome..."
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    autocomplete="off"
                  />
                </div>
                <div className="form-group">
                  <label className="">Preço:</label>
                  <input
                    type="number"
                    className="form-control"
                    id="price"
                    placeholder="Preço..."
                    value={price}
                    onChange={(e) => {
                      setPrice(e.target.value);
                    }}
                    autocomplete="off"
                  />
                </div>
                <div className="form-group">
                  <label className="">Preço Antigo:</label>
                  <input
                    type="number"
                    className="form-control"
                    id="price_old"
                    placeholder="Preço Antigo..."
                    value={priceOld}
                    onChange={(e) => {
                      setPriceOld(e.target.value);
                    }}
                    autocomplete="off"
                  />
                </div>

                <div>
                  <label className="">Descrição:</label>
                  <div
                    className="editor"
                    style={{ listStylePosition: "inside" }}
                  >
                    <CKEditor
                      config={{
                        removePlugins: [
                          "ImageUpload",
                          "EasyImage",
                          "Image",
                          "ImageCaption",
                          "ImageStyle",
                          "ImageToolbar",
                          "Table",
                          "TableToolbar",
                          "MediaEmbed",
                        ],
                      }}
                      editor={ClassicEditor}
                      data={description}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setDescription(data);
                      }}
                    />
                  </div>
                </div>

                <label className="">Imagens:</label>
                <ImageUploading
                  multiple
                  value={
                    images.length > 0
                      ? images.sort((a, b) => (a.orderId > b.orderId ? 1 : -1))
                      : images
                  }
                  onChange={onNewImageChange}
                  maxNumber={maxNumber}
                  dataURLKey="data_url"
                  images={images}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                      <button
                        style={isDragging ? { color: "white" } : undefined}
                        onClick={(e) => {
                          e.preventDefault();
                          onImageUpload();
                        }}
                        {...dragProps}
                      >
                        Click or Drop here
                      </button>
                      &nbsp;
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          onImageRemoveAll();
                        }}
                      >
                        Remove all images
                      </button>
                      <div className="d-flex flex-wrap">
                        {imageList.map((image, index) => (
                          <div key={index} className="image-item ">
                            <img
                              src={
                                image["data_url"]
                                  ? image["data_url"]
                                  : image["url"]
                              }
                              alt=""
                              width="100"
                            />
                            <div className="image-item__btn-wrapper">
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  onImageUpdate(index);
                                }}
                              >
                                Update
                              </button>
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  onImageRemove(index);
                                }}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </ImageUploading>
                <div className="form-group">
                  <label className="">Pais:</label>
                  <select
                    value={country}
                    onChange={(e) => {
                      setCountry(e.target.value);
                    }}
                    className="custom-select"
                  >
                    <option value="0">Outro</option>
                    <option value="1">Portugal</option>
                  </select>
                </div>
                <div className="form-group">
                  <label className="">Distrito:</label>
                  <select
                    value={district}
                    onChange={(e) => {
                      setDistrict(e.target.value);
                    }}
                    className="custom-select"
                  >
                    <option value="0">Outro</option>
                    <option value="1">Coimbra</option>
                  </select>
                </div>
                <div className="form-group">
                  <label className="">Concelho:</label>
                  <select
                    value={county}
                    onChange={(e) => {
                      setCounty(e.target.value);
                    }}
                    className="custom-select"
                  >
                    <option value="0">Outro</option>
                    <option value="1">Coimbra</option>
                    <option value="2">Lousã</option>
                    <option value="3">Miranda</option>
                    <option value="4">Gois</option>
                    <option value="5">Vila Nova de Poiares</option>
                    <option value="6">Arganil</option>
                    <option value="7">Oliveira do Hospital</option>
                  </select>
                </div>
                <div className="form-group">
                  <label className="">Ano da Construção:</label>
                  <input
                    type="number"
                    className="form-control"
                    id="year_construction"
                    placeholder="Ano da Construção..."
                    value={yearConstruction}
                    onChange={(e) => {
                      setYearConstruction(e.target.value);
                    }}
                    autocomplete="off"
                  />
                </div>

                <div className="form-group">
                  <label className="">Tipo:</label>
                  <select
                    value={propertyType}
                    onChange={(e) => {
                      setPropertyType(e.target.value);
                    }}
                    className="custom-select"
                  >
                    <option value="0">Tipo...</option>
                    <option value="1">Apartamento</option>
                    <option value="2">Escritório</option>
                    <option value="3">Imóveis com Negócio</option>
                    <option value="4">Loja</option>
                    <option value="5">Moradia</option>
                    <option value="6">Prédio</option>
                    <option value="7">Quarto</option>
                    <option value="8">Quintas e Herdades</option>
                    <option value="9">Terreno</option>
                  </select>
                </div>

                <div className="form-group">
                  <label className="">Nº Quartos:</label>
                  <select
                    value={typology}
                    onChange={(e) => {
                      setTypology(e.target.value);
                    }}
                    className="custom-select"
                  >
                    <option value="0">Nº Quartos...</option>
                    <option value="1">0 Quartos</option>
                    <option value="2">1 Quarto</option>
                    <option value="3">2 Quartos</option>
                    <option value="4">3 Quartos</option>
                    <option value="5">4 Quartos</option>
                    <option value="6">5 Quartos</option>
                    <option value="7">6 Quartos</option>
                    <option value="8">7 Quartos</option>
                    <option value="9">8 Quartos</option>
                    <option value="10">9 Quartos</option>
                    <option value="11">10 Quartos +</option>
                  </select>
                </div>

                <div className="form-group">
                  <label className="">Objectivo:</label>
                  <select
                    value={objective}
                    onChange={(e) => {
                      setObjective(e.target.value);
                    }}
                    className="custom-select"
                  >
                    <option value="0">Objectivo...</option>
                    <option value="1">Vender</option>
                    <option value="2">Arrendar</option>
                    <option value="3">Trespasse</option>
                  </select>
                </div>

                <div className="form-group">
                  <label className="">Estado:</label>
                  <select
                    value={condition}
                    onChange={(e) => {
                      setCondition(e.target.value);
                    }}
                    className="custom-select"
                  >
                    <option value="0">Estado...</option>
                    <option value="1">Novo</option>
                    <option value="2">Usado </option>
                    <option value="3">Em Construção </option>
                    <option value="4">Para Remodelar</option>
                    <option value="5">Remodelado</option>
                  </select>
                </div>
                <div>
                  <label className="">Opções Avançadas:</label>
                  <div className="d-flex flex-wrap justify-content-between">
                    <div style={{ minWidth: "100px" }}>
                      <p className="m-0">Activa:</p>
                      <Switch
                        onChange={(checked) => {
                          setActiveToggle(checked);
                        }}
                        checked={activeToggle}
                      />
                    </div>
                    <div style={{ minWidth: "100px" }}>
                      <p className="m-0">Destaque:</p>
                      <Switch
                        onChange={(checked) => {
                          setHighlightToggle(checked);
                          setSoldToggle(false);
                          setReservedToggle(false);
                        }}
                        checked={highlightToggle}
                      />
                    </div>
                    <div style={{ minWidth: "100px" }}>
                      <p className="m-0">Reservada:</p>
                      <Switch
                        onChange={(checked) => {
                          setReservedToggle(checked);
                          setSoldToggle(false);
                          setHighlightToggle(false);
                        }}
                        checked={reservedToggle}
                      />
                    </div>
                    <div style={{ minWidth: "100px" }}>
                      <p className="m-0">Vendida:</p>
                      <Switch
                        onChange={(checked) => {
                          setSoldToggle(checked);
                          setReservedToggle(false);
                          setHighlightToggle(false);
                        }}
                        checked={soldToggle}
                      />
                    </div>
                  </div>
                </div>

                {(houseRef == "" && (
                  <button
                    type="submit"
                    className="btn btn-secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      handleAddHouse();
                    }}
                  >
                    Guardar nova casa
                  </button>
                )) || (
                  <button
                    type="submit"
                    className="btn btn-secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      handleAddHouseUpdate();
                    }}
                  >
                    Guardar alterações
                  </button>
                )}
              </form>
            </div>
          </div>
          <button onClick={singOut} className="btn btn-default">
            SingOut
          </button>
        </div>
      )}
    </>
  );
}

export default PageAdmin;
