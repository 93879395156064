import React from "react";
import { Link } from "react-router-dom";
import SuggestionItem from "./suggestionItem/SuggestionItem";
import { useTranslation } from "react-i18next";

function Suggestions({ houses }) {
  const { t, i18n } = useTranslation();

  return (
    <>
      <h1
        style={{ textAlign: "center" }}
        className="pt-4 pb-2 d-flex justify-content-around"
      >
        {t("featured_properties")}
      </h1>
      <div
        className="d-flex flex-wrap m-auto justify-content-around"
        style={{ width: "95vw", minHeight: "80vh" }}
      >
        {houses.length === 0
          ? null
          : houses.map((house, index) => {
              return (
                <SuggestionItem house={house} index={index}></SuggestionItem>
              );
            })}
        <div
          style={{ width: "400px", maxWidth: "800px", minHeight: "1px" }}
          className="h-100 m-0 flex-fill"
        ></div>
        <div
          style={{ width: "400px", maxWidth: "800px", minHeight: "1px" }}
          className="h-100 m-0 flex-fill"
        ></div>
        <div
          style={{ width: "400px", maxWidth: "800px", minHeight: "1px" }}
          className="h-100 m-0 flex-fill"
        ></div>
        <div
          style={{ width: "400px", maxWidth: "800px", minHeight: "1px" }}
          className="h-100 m-3 flex-fill"
        ></div>
      </div>
    </>
  );
}

export default Suggestions;
