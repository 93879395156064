import React from 'react'
import Imgg from "../../../resources/img/interior-room.jpeg";

function AboutHero() {
    return (
        <div
        className="d-flex flex-column align-items-center justify-content-center m-auto"
        style={{
          height: "50vh",
          width: "100%",
          backgroundImage: "url(" + Imgg + ")",
          backgroundSize: "cover",
          backgroundPosition: "calc(100%) calc(40%)",
        }}
      >
        <h1 style={{color:"white"}} className="mb-4">QUEM SOMOS</h1>
      </div>
    )
}

export default AboutHero
