import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Logo from "../../resources/img/logo-nobg2.png";

function NavMenu({ i18n }) {
  const changeLanguage = (ln) => {
    return () => {
      i18n.changeLanguage(ln);
    };
  };

  return (
    <>
      <Navbar
        className="p-0"
        bg="light"
        expand="lg"
        sticky="top"
        style={{ minHeight: "5vh" }}
      >
        <NavLink className="navbar-brand mx-5" exact to="/">
          <img src={Logo} alt="Logo" style={{ width: "110px" }} />
        </NavLink>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="d-flex justify-content-around w-100">
            <NavLink
              className="px-4"
              exact
              to="/"
              activeStyle={{
                fontWeight: "bold",
                fontSize: 20,
                color: "black",
              }}
              style={{
                color: "black",
                textDecoration: "none",
              }}
            >
              Home
            </NavLink>
            <NavLink
              className="px-4"
              exact
              to="/vendercasa"
              activeStyle={{
                fontWeight: "bold",
                fontSize: 20,
                color: "black",
              }}
              style={{
                color: "black",
                textDecoration: "none",
              }}
            >
              Quer Vender
            </NavLink>
            <NavLink
              className="px-4"
              exact
              to="/quemsomos"
              activeStyle={{
                fontWeight: "bold",
                fontSize: 20,
                color: "black",
              }}
              style={{
                color: "black",
                textDecoration: "none",
              }}
            >
              Quem Somos
            </NavLink>
            <NavLink
              className="px-4"
              to="/contacts"
              activeStyle={{
                fontWeight: "bold",
                fontSize: 20,
                color: "black",
              }}
              style={{
                color: "black",
                textDecoration: "none",
              }}
            >
              Contactos
            </NavLink>
            <div className="d-none">
              <button onClick={changeLanguage("en")}>EN</button>
              <button onClick={changeLanguage("pt")}>PT</button>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

export default NavMenu;
