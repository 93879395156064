import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import SuggestionItem from "../home/suggestions/suggestionItem/SuggestionItem";
import NavMenu from "../navMenu/NavMenu";
import firebase from "./../../firebase/index";
import FilterHouse from "./filterHouse/filterHouse";
import HeroFilter from "./heroFilter/HeroFilter";
import MyFooter from "./../footer/MyFooter";

function Filter(props) {
  const [objective, setObjective] = useState(props.location.state.objective);
  const [typology, setTypology] = useState(props.location.state.typology);
  const [propertyType, setPropertyType] = useState(
    props.location.state.propertyType
  );
  const [district, setDistrict] = useState(props.location.state.district);
  const [county, setCounty] = useState(props.location.state.county);
  const [condition, setCondition] = useState(props.location.state.condition);
  const [minPrice, setMinPrice] = useState(
    parseInt(props.location.state.minPrice)
  );
  const [maxPrice, setMaxPrice] = useState(
    parseInt(props.location.state.maxPrice)
  );

  const [houses, setHouses] = useState([]);

  useEffect(() => {
    getHouses();
  }, [
    objective,
    typology,
    propertyType,
    district,
    county,
    condition,
    minPrice,
    maxPrice,
  ]);

  const getHouses = async () => {
    setHouses([]);
    var query = firebase.db.collection("housesDescription");
    if (objective > 0) query = query.where("objective", "==", objective);
    if (typology > 0) query = query.where("typology", "==", typology);
    if (propertyType > 0)
      query = query.where("property_type", "==", propertyType);
    if (county > 0) query = query.where("county", "==", county);
    if (condition > 0) query = query.where("condition", "==", condition);
    if (district > 0) query = query.where("district", "==", district);
    if (minPrice > 0) query = query.where("price", ">=", minPrice);
    if (maxPrice > 0) query = query.where("price", "<=", maxPrice);

    query
      .where("active", "==", true)
      .orderBy("price")
      .orderBy("highlight", "desc")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setHouses((prev) => [...prev, doc.data()]);
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const searchHouse = (
    new_objective,
    new_typology,
    new_property_type,
    district,
    county,
    condition,
    minPrice,
    maxPrice
  ) => {
    setObjective(new_objective);
    setTypology(new_typology);
    setPropertyType(new_property_type);
    setCounty(county);
    setDistrict(district);
    setCondition(condition);
    setMinPrice(parseInt(minPrice));
    setMaxPrice(parseInt(maxPrice));
    //invoca o useEffect
  };

  return (
    <div>
      <NavMenu></NavMenu>
      <HeroFilter
        prop_objective={objective}
        prop_typology={typology}
        prop_propertyType={propertyType}
        searchHouse={searchHouse}
      ></HeroFilter>
      <p className="pt-5"></p>
      <div
        className="d-flex flex-wrap m-auto justify-content-around"
        style={{ width: "90vw", minHeight: "50vh" }}
      >
        {houses.length === 0 ? (
          <div className="d-flex w-100 h-100">
            <h2
              className="mx-auto text-center"
              style={{ fontSize: "18px", color: "grey" }}
            >
              Não foram encontrados imóveis
            </h2>
          </div>
        ) : (
          houses.map((house, index) => {
            return <FilterHouse house={house}></FilterHouse>;
          })
        )}
        <div
          style={{ width: "400px", maxWidth: "550px", minHeight: "1px" }}
          className="h-100 m-2 flex-fill"
        ></div>
        <div
          style={{ width: "400px", maxWidth: "550px", minHeight: "1px" }}
          className="h-100 m-2 flex-fill"
        ></div>
        <div
          style={{ width: "400px", maxWidth: "550px", minHeight: "1px" }}
          className="h-100 m-2 flex-fill"
        ></div>
        <div
          style={{ width: "400px", maxWidth: "550px", minHeight: "1px" }}
          className="h-100 m-2 flex-fill"
        ></div>
      </div>
      <MyFooter></MyFooter>
    </div>
  );
}

export default Filter;
