import React, { useState, useEffect } from "react";
import { Button, Collapse } from "react-bootstrap";
import { Link } from "react-router-dom";
import Imgg from "../../../resources/img/interior-room.jpeg";
import AdvanceFilterFilter from "../AdvanceFilterFilter/AdvanceFilterFilter";
import { Sliders } from "react-bootstrap-icons";

function HeroFilter({
  prop_objective,
  prop_typology,
  prop_propertyType,
  searchHouse,
}) {
  const [objective, setObjective] = useState(prop_objective);
  const [typology, setTypology] = useState(prop_typology);
  const [propertyType, setPropertyType] = useState(prop_propertyType);

  const [hideLink, setHideLink] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setHideLink(open);
  }, [open]);

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-start m-auto justify-content-lg-center"
      style={{
        minHeight: "61vh",
        width: "100%",
        backgroundImage: "url(" + Imgg + ")",
        backgroundSize: "cover",
        backgroundPosition: "calc(100%) calc(40%)",
      }}
    >
      <h2 className="mt-2 pt-4 pt-lg-0 pb-3 pb-lg-4" style={{ color: "white" }}>
        COMPRAR CASA
      </h2>
      <div
        style={{ width: "60vw" }}
        className="d-flex flex-wrap _select_options_filter"
      >
        <div className="h-100 flex-fill _hero_filter">
          <div className="form-group h-100">
            <select
              value={objective}
              onChange={(e) => {
                setObjective(e.target.value);
              }}
              className="custom-select custom-select-lg h-100 p-1"
            >
              <option value="0">Objectivo...</option>
              <option value="1">Comprar</option>
              <option value="2">Arrendar</option>
              <option value="3">Trespasse</option>
            </select>
          </div>
        </div>
        <div className="h-100 flex-fill _hero_filter">
          <div className="form-group h-100">
            <select
              value={propertyType}
              onChange={(e) => {
                setPropertyType(e.target.value);
              }}
              className="custom-select custom-select-lg h-100 p-1"
            >
              <option value="0">Tipo...</option>
              <option value="1">Apartamentos</option>
              <option value="2">Escritórios</option>
              <option value="3">Imóveis com Negócio</option>
              <option value="4">Lojas</option>
              <option value="5">Moradias</option>
              <option value="6">Prédios</option>
              <option value="7">Quarto</option>
              <option value="8">Quintas e Herdades</option>
              <option value="9">Terrenos</option>
            </select>
          </div>
        </div>
        <div className="h-100 flex-fill _hero_filter">
          <div className="form-group h-100">
            <select
              value={typology}
              onChange={(e) => {
                setTypology(e.target.value);
              }}
              className="custom-select custom-select-lg h-100 p-1"
            >
              <option value="0">Nº Quartos...</option>
              <option value="2">1 Quarto</option>
              <option value="3">2 Quartos</option>
              <option value="4">3 Quartos</option>
              <option value="5">4 Quartos</option>
              <option value="6">5 Quartos</option>
              <option value="7">6 Quartos</option>
              <option value="8">7 Quartos</option>
              <option value="9">8 Quartos</option>
              <option value="10">9 Quartos</option>
              <option value="11">10 Quartos +</option>
            </select>
          </div>
        </div>
        <div className="h-100 d-flex flex-fill _hero_filter">
          <button
            className="btn btn-dark h-100 w-100"
            onClick={() => {
              searchHouse(objective, typology, propertyType);
            }}
          >
            Pesquisar
          </button>
          <Button
            onClick={() => setOpen(!open)}
            aria-controls="example-collapse-text"
            aria-expanded={open}
            className="btn btn-dark h-100 w-100"
            style={{ maxWidth: "80px" }}
          >
            <Sliders size={30}></Sliders>
          </Button>
        </div>
        <div className="d-flex w-100 justify-content-center">
          <a
            className={hideLink ? "d-none text-center" : " text-center"}
            onClick={() => {
              setOpen(!open);
            }}
          >
            <strong className="_advanceLink">
              Experimenta a nossa pesquisa avançada!
            </strong>
          </a>
        </div>
        <p className="lead px-4 pb-3 mb-3 text-white"></p>
      </div>
      <Collapse in={open} animation="false">
        <div
          className=""
          style={{ zIndex: "1", position: "relative", top: "-80px" }}
        >
          <AdvanceFilterFilter
            open={open}
            setOpen={setOpen}
            searchHouse={searchHouse}
          ></AdvanceFilterFilter>
        </div>
      </Collapse>
    </div>
  );
}

export default HeroFilter;
