import React from "react";

function ContactsText() {
  return (
    <div className="_contactContainer m-auto">
      <h1 className="text-center mt-4 mb-3 _contactTextTitle">
        Somos uma empresa com larga experiência comprovada, sendo os nossos
        clientes a melhor prova disso
      </h1>
      <p className="text-justify">
        Dedicamo-nos ao ramo da mediação imobiliária, quer na venda e
        arrendamento de imóveis, como na gestão do património dos nossos
        clientes, sendo a região de Coimbra nossa área predileta de atuação.
      </p>
      <p className="text-justify">
        Tendo um profundo conhecimento do mercado imobiliário e respetiva
        legislação, trabalhamos para o satisfazer e para o ajudar a fazer a sua
        melhor escolha.
      </p>
      <p className="text-justify">
        Com trabalho, dedicação e paixão queremos receber de si o nosso maior
        prestigio: "A sua lealdade".
      </p>
      <p className="text-justify">
        Acreditamos num serviço diferenciado, onde o cliente é acima de tudo
        alguém muito especial
      </p>
      <p className="text-justify">
        Obrigado a todos os que nos ajudaram a chegar aqui!
      </p>
    </div>
  );
}

export default ContactsText;
