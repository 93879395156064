import React, { useState } from "react";
import emailjs from "emailjs-com";
import NavMenu from "../navMenu/NavMenu";
import VenderCasaHero from "./venderCasaHero/VenderCasaHero";
import { Link } from "react-router-dom";
import MyFooter from "../footer/MyFooter";
import { useTranslation } from "react-i18next";

function VenderCasa({ i18n }) {
  const { t, i18 } = useTranslation();
  const [emailEnviado, setEmailEnviado] = useState(false);
  const [erroCampos, setErroCampos] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    let nameVal = e.target.elements[0].value;
    let emailVal = e.target.elements[1].value;
    let contactVal = e.target.elements[2].value;

    if (nameVal == "" || emailVal == "" || contactVal == "") {
      setErroCampos(true);
    } else {
      setErroCampos(false);
      setEmailEnviado(true);

      emailjs
        .sendForm(
          "gmail",
          "template_jdga49o",
          e.target,
          "user_8r8HaYp7bty5nBTb6qvuj"
        )
        .then(
          (result) => {
            console.log("email enviado");
            console.log(result.text);
          },
          (error) => {
            console.log("erro enviar email");
            console.log(error.text);
          }
        );
      e.target.reset();
    }
  };
  return (
    <>
      <NavMenu i18n={i18n}></NavMenu>
      <VenderCasaHero></VenderCasaHero>

      <div style={{ width: "90vw", minHeight: "90vh" }} className="m-auto">
        <div className="d-flex flex-column align-items-center justify-content-center m-auto h-100 pt-5">
          {emailEnviado == true ? (
            <div className="pt-5">
              <h3>{t("sellHouse_thanks1")}</h3>
              <p>{t("sellHouse_thanks2")}</p>
              <Link
                to={"/"}
                className="h-100 m-2 d-flex justify-content-center"
                style={{ textDecoration: "none" }}
              >
                <button type="button" className="btn btn-secondary">
                  {t("home_page")}
                </button>
              </Link>
            </div>
          ) : (
            <form onSubmit={sendEmail}>
              <div className="mx-auto pb-5">
                <h3>{t("sellHouse_personalData_title")}</h3>
                <div className=" mx-auto">
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder={t("sellHouse_personalData_name")}
                    name="nome"
                  />

                  <div className="d-flex flex-wrap">
                    <input
                      type="email"
                      className="form-control flex-fill mb-3"
                      style={{ width: "300px" }}
                      placeholder={t("sellHouse_personalData_email")}
                      name="email"
                    />
                    <input
                      type="text"
                      className="form-control flex-fill mb-3"
                      style={{ width: "300px" }}
                      placeholder={t("sellHouse_personalData_contact")}
                      name="contacto"
                    />
                  </div>
                </div>
                <h3>{t("sellHouse_houseData_title")}</h3>
                <div className="mx-auto">
                  <div className="d-flex flex-wrap">
                    <select
                      className="custom-select flex-fill mb-3"
                      style={{ width: "350px" }}
                      name="property_type"
                    >
                      <option value="N/A">{t("type_title")}</option>
                      <option value="Apartamento">{t("type_apartment")}</option>
                      <option value="Escritório">{t("type_office")}</option>
                      <option value="Imóveis com Negócio">
                        {t("type_commercial_property")}
                      </option>
                      <option value="Loja">{t("type_store")}</option>
                      <option value="Moradia">{t("type_habitation")}</option>
                      <option value="Prédio">{t("type_building")}</option>
                      <option value="Quarto">{t("type_room")}</option>
                      <option value="Quintas e Herdades">
                        t{"type_farms_and_estates"}
                      </option>
                      <option value="Terreno">{t("type_land")}</option>
                    </select>
                    <select
                      className="custom-select flex-fill mb-3"
                      style={{ width: "350px" }}
                      name="typology"
                    >
                      <option value="N/A">{t("room_title")}</option>
                      <option value="0 Quartos">{t("room_0")}</option>
                      <option value="1 Quarto">{t("room_1")}</option>
                      <option value="2 Quartos">{t("room_2")}</option>
                      <option value="3 Quartos">{t("room_3")}</option>
                      <option value="4 Quartos">{t("room_4")}</option>
                      <option value="5 Quartos">{t("room_5")}</option>
                      <option value="6 Quartos">{t("room_6")}</option>
                      <option value="7 Quartos">{t("room_7")}</option>
                      <option value="8 Quartos">{t("room_8")}</option>
                      <option value="9 Quartos">{t("room_9")}</option>
                      <option value="10 Quartos +">{t("room_10")}</option>
                    </select>
                    <input
                      type="number"
                      className="form-control flex-fill mb-3"
                      style={{ width: "350px" }}
                      placeholder={t("price_title")}
                      name="price"
                    />
                    <select
                      className="custom-select flex-fill mb-3"
                      style={{ width: "350px" }}
                      name="district"
                    >
                      <option value="N/A">{t("district_title")}</option>
                      <option value="Coimbra">{t("district_coimbra")}</option>
                      <option value="Outro">{t("district_other")}</option>
                    </select>
                    <select
                      className="custom-select flex-fill mb-3"
                      style={{ width: "350px" }}
                      name="concelho"
                    >
                      <option value="N/A">{t("county_title")}</option>
                      <option value="Lousã">{t("county_lousa")}</option>
                      <option value="Miranda">{t("county_miranda")}</option>
                      <option value="Coimbra">{t("county_coimbra")}</option>
                      <option value="Outro">{t("county_other")}</option>
                    </select>

                    <div
                      className="flex-fill "
                      style={{ width: "350px", height: "1px" }}
                    >
                      {" "}
                    </div>
                    <div
                      className="flex-fill "
                      style={{ width: "350px", height: "1px" }}
                    >
                      {" "}
                    </div>
                    <div
                      className="flex-fill "
                      style={{ width: "350px", height: "1px" }}
                    >
                      {" "}
                    </div>
                    <div
                      className="flex-fill "
                      style={{ width: "350px", height: "1px" }}
                    >
                      {" "}
                    </div>
                    <textarea
                      className="form-control mb-3"
                      id="mensagem"
                      cols="30"
                      rows="8"
                      placeholder={t("sellHouse_houseData_description")}
                      name="mensagem"
                    ></textarea>
                  </div>
                </div>
                {erroCampos && (
                  <p className="text-center alert alert-warning">
                    {t("sellHouse_fill_error")}
                  </p>
                )}
                <div className="d-flex justify-content-center justify-content-md-start">
                  <input
                    type="submit"
                    className="btn btn-dark"
                    value={t("sellHouse_send")}
                  />
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
      <MyFooter></MyFooter>
    </>
  );
}

export default VenderCasa;
