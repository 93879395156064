import React from "react";
import Imgg from "../../../resources/img/interior-room.jpeg";

function DetailsHeader({ house, index }) {
  return (
    <div
      key={index}
      className="d-flex flex-column align-items-center justify-content-center m-auto"
      style={{
        height: "50vh",
        width: "100%",
        backgroundImage: "url(" + Imgg + ")",
        backgroundSize: "cover",
        backgroundPosition: "calc(100%) calc(40%)",
      }}
    >
      <h1 className="text-center">{house.name}</h1>
      {house.price != 0 ? (
        <h1 style={{ color: "white" }}>
          {house.price.toString().length > 3
            ? [
                house.price
                  .toString()
                  .slice(0, house.price.toString().length - 3),
                ".",
                house.price
                  .toString()
                  .slice(
                    house.price.toString().length - 3,
                    house.price.toString().length
                  ),
              ].join("")
            : house.price.toString()}
          €
        </h1>
      ) : (
        <h1 style={{ color: "white" }}>Sob Consulta</h1>
      )}
    </div>
  );
}

export default DetailsHeader;
