import React, { useEffect, useState } from "react";
import { BounceLoader } from "react-spinners";
import NavMenu from "../navMenu/NavMenu";
import parse from "html-react-parser";
import firebase from "./../../firebase/index";
import AboutHero from "./aboutHero/AboutHero";
import MyFooter from "../footer/MyFooter";

function About() {
  const [quemSomos, setQuemSomos] = useState();

  useEffect(() => {
    getAbout();
  }, []);

  const getAbout = () => {
    firebase.db
      .collection("globalData")
      .doc("quemsomos")
      .get()
      .then((doc) => {
        setQuemSomos(doc.data());
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  return (
    <>
      <NavMenu></NavMenu>
      <AboutHero></AboutHero>
      {quemSomos == undefined ? (
        <div style={{ width: "95vw", minHeight: "90vh" }} className="m-auto">
          <div className="d-flex flex-column align-items-center justify-content-center m-auto h-100 pt-5">
            <BounceLoader loading size={75} />
            <p className="pt-2">Search...</p>
          </div>
        </div>
      ) : (
        <div style={{ width: "95vw", minHeight: "90vh" }} className="m-auto">
          <h3 className="text-center pt-3">A nossa Missão</h3>
          {parse(quemSomos.missao)}
          <h3 className="text-center pt-3">A nossa Visão</h3>
          {parse(quemSomos.visao)}
          <h3 className="text-center pt-3">Os nossos Valores</h3>
          {parse(quemSomos.valores)}
        </div>
      )}

      <MyFooter></MyFooter>
    </>
  );
}

export default About;
