import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import DetailsHouseDescription from "../detailsHouseDescription/DetailsHouseDescription";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import DetailsHouseCharacteristics from "../detailsHouseCharacteristics/DetailsHouseCharacteristics";
import DetailsHouseEmail from "../detailsHouseEmail/DetailsHouseEmail";

function DetailsHouse({ house, index }) {
  const [gallery, setGallery] = useState([]);

  useEffect(() => {
    setGallery(
      house.images.map((img) => ({
        original: img.url,
        thumbnail: img.url,
        orderId: img.orderId,
      }))
    );
  }, []);

  return (
    <div
      key={index}
      className="d-flex m-auto flex-wrap"
      style={{ minHeight: "70vh" }}
    >
      <div
        className="flex-fill flex-column"
        style={{ minWidth: "60vw", minHeight: "800px" }}
      >
        <div className="gallery_container pt-4">
          <ImageGallery
            items={gallery.sort((a, b) => (a.orderId > b.orderId ? 1 : -1))}
            showBullets={true}
          />
        </div>

        <div
          className="px-4 my-3 _houseDescription"
          style={{ minHeight: "100px" }}
        >
          <DetailsHouseDescription house={house}></DetailsHouseDescription>
        </div>
      </div>
      <div
        className="d-flex flex-fill flex-wrap _houseEmailAndCharactContainer"
        style={{ width: "400px", minHeight: "100px" }}
      >
        <div className="px-5 py-2 flex-fill" style={{ minHeight: "100px" }}>
          <DetailsHouseEmail houseRef={house.ref}></DetailsHouseEmail>
        </div>
        <div
          className="d-flex flex-fill flex-wrap justify-content-around"
          style={{ minHeight: "100px", padding: "10px 15px 0 15px " }}
        >
          <DetailsHouseCharacteristics
            house={house}
          ></DetailsHouseCharacteristics>
        </div>
      </div>
      {/* <div className="w-100 h-100 d-flex flex-row flex-wrap">
        <div
          style={{ width: "65vw", minHeight: "500px"}}
        >
          <div className="gallery_container pt-4">
          <ImageGallery items={gallery} showBullets={true} />
          </div>
        </div>
        <div
          style={{ width: "30vw", minHeight: "500px"}}
        >
          <DetailsHouseEmail houseRef = {house.ref}></DetailsHouseEmail>
        </div>
      </div>
      <div className="w-100 h-100 d-flex flex-row flex-wrap">
        <div
          style={{ width: "65vw", minHeight: "500px"}}
        >
          <DetailsHouseDescription house={house}></DetailsHouseDescription>
        </div>
        <div
          style={{ width: "30vw", minHeight: "500px"}}
        >
          <DetailsHouseCharacteristics house={house}></DetailsHouseCharacteristics>
        </div>
      </div> */}
    </div>
  );
}

export default DetailsHouse;
