import React from "react";

function ContactsEquipa(props) {
  return (
    <div className="_contactContainer m-auto">
      <div className="d-flex justify-content-around m-5 flex-wrap">
        <div className="flex-fill mb-3" style={{ minHeight: "100px" }}>
          <div
            className="m-auto "
            style={{
              width: "300px",
              minHeight: "100px",
            }}
          >
            <div
              className="mb-2 _contactsFoto"
              style={{
                backgroundImage:
                  "url(https://firebasestorage.googleapis.com/v0/b/xisto-village-imo.appspot.com/o/Susejfif?alt=media&token=43cb328c-d332-4971-a0c1-516d0268ec51)",
                backgroundSize: "cover",
              }}
            ></div>
            <p
              className="m-0 text-center"
              style={{ fontSize: "24px", fontWeight: "bold" }}
            >
              Suse Pinto
            </p>
            <p className="m-0 text-center">Diretora da XistoVillage</p>
            <p className="m-0 text-center" style={{ fontSize: "20px" }}>
              912132662
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactsEquipa;
