import React from "react";
import { BounceLoader } from "react-spinners";
import NavMenu from "../navMenu/NavMenu";
import ContactHero from "./contactsHero/ContactHero";
import ContactsText from "./contactsText/ContactsText";
import ContactsEquipa from "./contactsEquipa/ContactsEquipa";
import MyFooter from "../footer/MyFooter";

function Contacts() {
  return (
    <>
      <NavMenu></NavMenu>
      <ContactHero></ContactHero>
      <ContactsText></ContactsText>
      <ContactsEquipa></ContactsEquipa>
      <MyFooter></MyFooter>
    </>
  );
}

export default Contacts;
