import React, { useState } from "react";

function LoginAdmin({ handleLogin }) {
  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ height: "80vh" }}
    >
      <div className=" w-25 bg-dark p-5 rounded">
        <form className="d-flex flex-column align-items-center">
          <div className="form-group">
            <label className="text-white">UserName</label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Enter email"
              value={state.email}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label className="text-white">Password</label>
            <input
              type="password"
              className="form-control"
              id="password"
              placeholder="Enter password"
              value={state.password}
              onChange={handleChange}
            />
          </div>
          <button
            type="submit"
            className="btn btn-secondary"
            onClick={(e) => {
              e.preventDefault();
              handleLogin(state.email, state.password);
            }}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default LoginAdmin;
