import React, { useState, useEffect } from "react";
import LoginAdmin from "./loginAdmin/LoginAdmin";
import NavMenuAdmin from "./navMenuAdmin/NavMenuAdmin";
import PageAdmin from "./pageAdmin/PageAdmin";
import firebase from "./../../firebase/index";
import Bootbox from "bootbox-react";

function Adminis({ firebase }) {
  const [userL, setUserL] = useState();
  const [houses, setHouses] = useState([]);
  const [houseRef, setHouseRef] = useState("");
  const [houseRefDelete, setHouseRefDelete] = useState("");

  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    authListener();
    if (setUserL) {
      getHouses();
    }
  }, []);

  const getHouses = () => {
    firebase.db
      .collection("houses")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setHouses((prev) => [...prev, doc.data()]);
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const authListener = () => {
    firebase.auth.onAuthStateChanged((user) => {
      if (user) {
        setUserL(user);
      } else {
        setUserL(undefined);
      }
    });
  };

  const handleLogin = (email, pass) => {
    firebase.auth
      .signInWithEmailAndPassword(email, pass)
      .then((u) => {
        setUserL(u);
      })
      .catch((err) => {
        console.log("Erro no Login");
      });
  };

  const handleChoseHouse = (ref) => {
    setHouseRef(ref);
  };

  const handleCreateDeleteBootbox = (refHouse) => {
    setShowConfirm(true);
    setHouseRefDelete(refHouse);
  };

  const handleConfirm = () => {
    firebase.db
      .collection("houses")
      .where("ref", "==", houseRefDelete)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          doc.ref.delete().then(() => {
            firebase.db
              .collection("housesDescription")
              .where("ref", "==", houseRefDelete)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach(function (doc) {
                  doc.ref.delete().then(() => {
                    setShowConfirm(false);
                    setHouseRef("");
                    setHouses(
                      houses.filter((item) => item.ref !== houseRefDelete)
                    );
                  });
                });
              })
              .catch((err) => {
                console.log(err.message);
              });
          });
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleCancel = () => {
    return setShowConfirm(false);
  };

  const handleClose = () => {
    return setShowConfirm(false);
  };

  return (
    <>
      <script src="https://www.gstatic.com/firebasejs/7.22.0/firebase-app.js"></script>
      {userL ? (
        <div className="d-flex">
          <NavMenuAdmin
            houses={houses}
            handleChoseHouse={handleChoseHouse}
            handleCreateDeleteBootbox={handleCreateDeleteBootbox}
          ></NavMenuAdmin>
          <PageAdmin
            firebase={firebase}
            houseRef={houseRef}
            handleChoseHouse={handleChoseHouse}
          ></PageAdmin>
          <Bootbox
            show={showConfirm}
            type={"confirm"}
            message={`Tem a certeza que quer apagar o imóvel ${houseRefDelete}`}
            onSuccess={handleConfirm}
            onCancel={handleCancel}
            onClose={handleClose}
          />
        </div>
      ) : (
        <LoginAdmin handleLogin={handleLogin}></LoginAdmin>
      )}
    </>
  );
}

export default Adminis;
