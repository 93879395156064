import React, { useEffect, useState } from "react";
import HeroImg from "../../../resources/img/hero.jpg";
import { Collapse, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import AdvanceFilter from "../advanceFilter/AdvanceFilter";
import { Sliders } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

function Hero() {
  const [objective, setObjective] = useState("");
  const [typology, setTypology] = useState("");
  const [propertyType, setPropertyType] = useState("");

  const [zona, setZona] = useState("2");

  const [hideLink, setHideLink] = useState(false);
  const [open, setOpen] = useState(false);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    setHideLink(open);
  }, [open]);

  return (
    <div
      style={{
        minHeight: "90vh",
        backgroundImage: "url(" + HeroImg + ")",
        backgroundSize: "cover",
        backgroundPosition: "calc(30%) calc(50%)",
      }}
      className="container-fluid p-0 d-flex flex-column align-items-center "
    >
      <h1 className="display-4 px-4 text-white text-center _homeHeroText">
        Xisto Village
      </h1>
      <p className="lead px-4 pb-3 mb-3 text-white"></p>
      <div
        style={{ width: "60vw" }}
        className="d-flex flex-wrap _select_options"
      >
        <div className="h-100 flex-fill _hero_filter">
          <div className="form-group h-100">
            <select
              value={objective}
              onChange={(e) => {
                setObjective(e.target.value);
              }}
              className="custom-select custom-select-lg mb-3 h-100 p-1 "
              style={{}}
            >
              <option value="0">{t("objective_title")}</option>
              <option value="1">{t("objective_sell")}</option>
              <option value="2">{t("objective_rent")}</option>
              <option value="3">{t("objective_trespass")}</option>
            </select>
          </div>
        </div>
        <div className="h-100 flex-fill _hero_filter">
          <div className="form-group h-100">
            <select
              value={propertyType}
              onChange={(e) => {
                setPropertyType(e.target.value);
              }}
              className="custom-select custom-select-lg h-100 p-1"
            >
              <option value="0">{t("type_title")}</option>
              <option value="1">{t("type_apartment")}</option>
              <option value="2">{t("type_office")}</option>
              <option value="3">{t("type_commercial_property")}</option>
              <option value="4">{t("type_store")}</option>
              <option value="5">{t("type_habitation")}</option>
              <option value="6">{t("type_building")}</option>
              <option value="7">{t("type_room")}</option>
              <option value="8">{t("type_farms_and_estates")}</option>
              <option value="9">{t("type_land")}</option>
            </select>
          </div>
        </div>
        <div className="h-100 flex-fill _hero_filter">
          <div className="form-group h-100">
            <select
              value={typology}
              onChange={(e) => {
                setTypology(e.target.value);
              }}
              className="custom-select custom-select-lg h-100 p-1"
            >
              <option value="0">{t("room_title")}</option>
              <option value="2">{t("room_1")}</option>
              <option value="3">{t("room_2")}</option>
              <option value="4">{t("room_3")}</option>
              <option value="5">{t("room_4")}</option>
              <option value="6">{t("room_5")}</option>
              <option value="7">{t("room_6")}</option>
              <option value="8">{t("room_7")}</option>
              <option value="9">{t("room_8")}</option>
              <option value="10">{t("room_9")}</option>
              <option value="11">{t("room_10")}</option>
            </select>
          </div>
        </div>
        <div className="h-100 d-flex flex-fill _hero_filter">
          <Link
            to={{
              pathname: "/filter",
              state: {
                objective: objective,
                propertyType: propertyType,
                typology: typology,
              },
            }}
            className="flex-fill"
          >
            <button className="btn btn-dark h-100 w-100">{t("search")}</button>
          </Link>
          <Button
            onClick={() => setOpen(!open)}
            aria-controls="example-collapse-text"
            aria-expanded={open}
            className="btn btn-dark h-100 w-100"
            style={{ maxWidth: "80px" }}
          >
            <Sliders size={30}></Sliders>
          </Button>
        </div>
        <div className="d-flex w-100 justify-content-center">
          <a
            className={hideLink ? "d-none text-center" : " text-center"}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setOpen(!open);
            }}
          >
            <strong className="_advanceLink">{t("advance_filter_text")}</strong>
          </a>
        </div>
      </div>
      <Collapse in={open} animation="false">
        <div className="" style={{ position: "relative", top: "-80px" }}>
          <AdvanceFilter open={open} setOpen={setOpen}></AdvanceFilter>
        </div>
      </Collapse>

      <p className="px-4 pb-5 mb-5"></p>
      <p className="px-4 pb-5 mb-5"></p>
    </div>
  );
}

export default Hero;
