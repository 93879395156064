import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function FilterHouse({ house }) {
  const [badgeText, setBadgeText] = useState("");
  const [badgeClass, setBadgeClass] = useState("");
  const Distrito = {
    0: "N/A",
    1: "Coimbra",
  };
  const Concelho = {
    0: "N/A",
    1: "Coimbra",
    2: "Lousã",
    3: "Miranda do Corvo",
    4: "Gois",
    5: "Vila Nova de Poiares",
    6: "Arganil",
    7: "Oliveira do Hospital",
  };

  useEffect(() => {
    if (house.highlight == true) {
      setBadgeText("Destaque");
      setBadgeClass("_badgeHighlight");
    }
    if (house.reserved == true) {
      setBadgeText("Reservada");
      setBadgeClass("_badgeReserved");
    }
    if (house.sold == true) {
      setBadgeText("Vendida");
      setBadgeClass("_badgeSold");
    }
  }, []);

  return (
    <Link
      style={{
        width: "400px",
        minHeight: "300px",
        maxWidth: "550px",
        color: "white",
        textDecoration: "none",
        overflow: "hidden",
      }}
      to={"/details/" + house.ref}
      className="d-flex flex-fill m-3"
    >
      <div
        className={`h-100 d-flex align-items-end flex-fill _img_hover ${badgeClass}`}
        data-label={badgeText}
        style={{
          backgroundImage:
            "url(" + house.images.find((img) => img.orderId == 0).url + ")",
          backgroundSize: "cover",
          backgroundPosition: "calc(100%) calc(70%)",
        }}
      >
        <div className="px-3 w-100 d-flex justify-content-between align-items-center _item_hover">
          <div>
            <h5 className="px-2 m-0 mt-4" style={{ fontSize: "14px" }}>
              {house.name}
            </h5>
            <p className="px-2 m-0 mb-4  _subtitle">
              {house.district != 0
                ? Concelho[house.county] + ", " + Distrito[house.district]
                : Concelho[house.county]}
            </p>
          </div>
          <div>
            {house.price != 0 ? (
              <h6 className="px-4 m-2" style={{ fontSize: "14px" }}>
                {house.price.toString().length > 3
                  ? [
                      house.price
                        .toString()
                        .slice(0, house.price.toString().length - 3),
                      ".",
                      house.price
                        .toString()
                        .slice(
                          house.price.toString().length - 3,
                          house.price.toString().length
                        ),
                    ].join("")
                  : house.price.toString()}
                €
              </h6>
            ) : (
              <h6
                className="px-4 m-2"
                style={{ fontSize: "14px", whiteSpace: "nowrap" }}
              >
                Sob Consulta
              </h6>
            )}
            {house.price_old != 0 &&
            house.price_old != null &&
            house.price != 0 ? (
              <h5
                className="px-4 m-2"
                style={{
                  fontSize: "14px",
                  color: "#BDC2BF",
                  textDecoration: "line-through",
                }}
              >
                {house.price_old.toString().length > 3
                  ? [
                      house.price_old
                        .toString()
                        .slice(0, house.price_old.toString().length - 3),
                      ".",
                      house.price_old
                        .toString()
                        .slice(
                          house.price_old.toString().length - 3,
                          house.price_old.toString().length
                        ),
                    ].join("")
                  : house.price_old.toString()}
                €
              </h5>
            ) : (
              <h5></h5>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
}

export default FilterHouse;
