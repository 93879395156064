import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import MyFooter from "../footer/MyFooter";
import NavMenu from "../navMenu/NavMenu";
import DetailsHeader from "./detailsHeader/DetailsHeader";
import DetailsHouse from "./detailsHouse/DetailsHouse";

function Details({ firebase }) {
  let { ref } = useParams();
  const [houses, setHouses] = useState([]);

  useEffect(() => {
    getHouses();
  }, []);

  const getHouses = () => {
    firebase.db
      .collection("housesDescription")
      .where("ref", "==", ref)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setHouses((prev) => [...prev, doc.data()]);
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <>
    <NavMenu></NavMenu>
    <div>
      {houses.length === 0 ? (
        <div className="d-flex flex-column align-items-center justify-content-center m-auto h-100">
          <BounceLoader loading size={75} />
          <p className="pt-2">Looking for houses...</p>
        </div>
      ) : (
        houses.map((house, index) => {
          if (index === 0) {
            return (
              <>
                <DetailsHeader house={house} index={index} />
                <DetailsHouse house={house} index={index}></DetailsHouse>
                <MyFooter index={index}></MyFooter>
              </>
            );
          } else {
            return <p key={index}>Error, pls report #001</p>;
          }
        })
      )}
    </div>
    </>
  );
}

export default Details;
