import React from "react";
import parse from "html-react-parser";
import "./DetailsHouseDescription.css";

function DetailsHouseDescription({ house, index }) {
  return (
    <div>
      <h2 style={{ color: "grey", textTransform: "uppercase" }}>
        {house.name}
      </h2>
      <div className="d-flex flex-row">
        {house.price != 0 ? (
          <h2>
            {house.price.toString().length > 3
              ? [
                  house.price
                    .toString()
                    .slice(0, house.price.toString().length - 3),
                  ".",
                  house.price
                    .toString()
                    .slice(
                      house.price.toString().length - 3,
                      house.price.toString().length
                    ),
                ].join("")
              : house.price.toString()}
            €
          </h2>
        ) : (
          <h2>Sob Consulta</h2>
        )}
        {house.price_old != 0 && house.price_old != null && house.price != 0 ? (
          <h5
            className="px-4 m-2"
            style={{
              fontSize: "20px",
              color: "#BDC2BF",
              textDecoration: "line-through",
            }}
          >
            {house.price_old.toString().length > 3
              ? [
                  house.price_old
                    .toString()
                    .slice(0, house.price_old.toString().length - 3),
                  ".",
                  house.price_old
                    .toString()
                    .slice(
                      house.price_old.toString().length - 3,
                      house.price_old.toString().length
                    ),
                ].join("")
              : house.price_old.toString()}
            €
          </h5>
        ) : (
          <h5></h5>
        )}
      </div>
      <h5 style={{ color: "grey" }}>Referência Imovel: {house.ref}</h5>
      <div className="px-2 house_description" style={{ color: "grey" }}>
        {parse(house.description)}
      </div>
    </div>
  );
}

export default DetailsHouseDescription;
