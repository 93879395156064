import React, { useState } from "react";
import {
  Navbar,
  Nav,
  Container,
  Accordion,
  Card,
  Button,
} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Trash } from "react-bootstrap-icons";

function NavMenuAdmin({ houses, handleChoseHouse, handleCreateDeleteBootbox }) {
  return (
    <>
      <Navbar
        collapseOnSelect
        bg="light"
        variant="dark"
        sticky="top"
        style={{ minHeight: "100vh", width: "15vw" }}
        className="p-0"
      >
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Container className="h-100">
          <Navbar.Collapse id="responsive-navbar-nav" className="h-100">
            <Nav className="_navbarlinksadmin d-flex flex-column h-100 justify-content-start align-items-start">
              <NavLink
                className="p-4 w-100"
                exact
                to="/"
                activeStyle={{
                  fontWeight: "bold",
                  fontSize: 20,
                }}
              >
                Home
              </NavLink>
              <Accordion defaultActiveKey="0" style={{ width: "15vw" }}>
                <Card style={{ width: "100%" }}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="1"
                    style={{ width: "100%" }}
                  >
                    Alterar Casas
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="1">
                    <div>
                      <button
                        className="p-3 _admin_houses"
                        autofocus="true"
                        onClick={() => {
                          handleChoseHouse("");
                        }}
                      >
                        Nova Casa +
                      </button>
                      <hr className="m-0"></hr>
                      {houses.length === 0
                        ? null
                        : houses.map((house, index) => {
                            return (
                              <>
                                <div className="">
                                  <button
                                    className="p-3 _admin_houses d-flex align-items-center justify-content-between"
                                    key="index"
                                    onClick={() => {
                                      handleChoseHouse(house.ref);
                                    }}
                                  >
                                    <span className="text-center w-100">
                                      {house.name}
                                    </span>
                                    <button
                                      className="mx-2"
                                      onClick={() =>
                                        handleCreateDeleteBootbox(house.ref)
                                      }
                                      style={{ height: "30px" }}
                                    >
                                      <Trash></Trash>
                                    </button>
                                  </button>
                                </div>
                                <hr className="m-0"></hr>
                              </>
                            );
                          })}
                    </div>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavMenuAdmin;
