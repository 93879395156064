import React from "react";

function DetailsHouseCharacteristics({ house }) {
  const Pais = {
    0: "N/A",
    1: "Portugal",
  };
  const Distrito = {
    0: "N/A",
    1: "Coimbra",
  };
  const Concelho = {
    0: "N/A",
    1: "Coimbra",
    2: "Lousã",
    3: "Miranda",
    4: "Gois",
    5: "Vila Nova de Poiares",
    6: "Arganil",
    7: "Oliveira do Hospital",
  };
  const Tipo = {
    0: "N/A",
    1: "Apartamento",
    2: "Escritório",
    3: "Imóvel com Negócio",
    4: "Loja",
    5: "Moradia",
    6: "Prédio",
    7: "Quarto",
    8: "Quintas e Herdades",
    9: "Terreno",
  };
  const N_Quartos = {
    0: "N/A",
    1: "0 Quartos",
    2: "1 Quarto",
    3: "2 Quartos",
    4: "3 Quartos",
    5: "4 Quartos",
    6: "5 Quartos",
    7: "6 Quartos",
    8: "7 Quartos",
    9: "8 Quartos",
    10: "9 Quartos",
    11: "10 Quartos +",
  };
  const Objectivo = {
    0: "N/A",
    1: "Vender",
    2: "Arrendar",
    3: "Trespasse",
  };
  const Estado = {
    0: "N/A",
    1: "Novo",
    2: "Usado",
    3: "Em Construção",
    4: "Para Remodelar",
    5: "Remodelado",
  };

  return (
    <>
      <div style={{ height: "80px", width: "150px" }}>
        <p className="m-0" style={{ fontWeight: "bold" }}>
          Tipo de imóvel
        </p>
        <p className="mb-2">
          {house.property_type ? Tipo[house.property_type] : "N/A"}
        </p>
        <hr className="my-0"></hr>
      </div>
      <div style={{ height: "80px", width: "150px" }}>
        <p className="m-0" style={{ fontWeight: "bold" }}>
          País
        </p>
        <p className="mb-2">{house.country ? Pais[house.country] : "N/A"}</p>
        <hr className="my-0"></hr>
      </div>
      <div style={{ height: "80px", width: "150px" }}>
        <p className="m-0" style={{ fontWeight: "bold" }}>
          Tipologia
        </p>
        <p className="mb-2">
          {house.typology ? N_Quartos[house.typology] : "N/A"}
        </p>
        <hr className="my-0"></hr>
      </div>
      <div style={{ height: "80px", width: "150px" }}>
        <p className="m-0" style={{ fontWeight: "bold" }}>
          Distrito
        </p>
        <p className="mb-2">
          {house.district ? Distrito[house.district] : "N/A"}
        </p>
        <hr className="my-0"></hr>
      </div>
      <div style={{ height: "80px", width: "150px" }}>
        <p className="m-0" style={{ fontWeight: "bold" }}>
          Objectivo
        </p>
        <p className="mb-2">
          {house.objective ? Objectivo[house.objective] : "N/A"}
        </p>
        <hr className="my-0"></hr>
      </div>
      <div style={{ height: "80px", width: "150px" }}>
        <p className="m-0" style={{ fontWeight: "bold" }}>
          Concelho
        </p>
        <p className="mb-2">{house.county ? Concelho[house.county] : "N/A"}</p>
        <hr className="my-0"></hr>
      </div>
      <div style={{ height: "80px", width: "150px" }}>
        <p className="m-0" style={{ fontWeight: "bold" }}>
          Estado
        </p>
        <p className="mb-2">
          {house.condition ? Estado[house.condition] : "N/A"}
        </p>
        <hr className="my-0"></hr>
      </div>
      <div style={{ height: "80px", width: "150px" }}>
        <p className="m-0" style={{ fontWeight: "bold" }}>
          Preço
        </p>
        <p className="mb-2">
          {house.price
            ? house.price.toString().length > 3
              ? [
                  house.price
                    .toString()
                    .slice(0, house.price.toString().length - 3),
                  ".",
                  house.price
                    .toString()
                    .slice(
                      house.price.toString().length - 3,
                      house.price.toString().length
                    ),
                ].join("")
              : house.price.toString() + " €"
            : "Sob Consulta"}
        </p>
        <hr className="my-0"></hr>
      </div>
      <div style={{ height: "80px", width: "150px" }}>
        <p className="m-0" style={{ fontWeight: "bold" }}>
          Ano Construção
        </p>
        <p className="mb-2">
          {house.year_construction ? house.year_construction : "N/A"}
        </p>
        <hr className="my-0"></hr>
      </div>
      <div style={{ height: "1px", width: "150px" }}></div>
      <div style={{ height: "1px", width: "150px" }}></div>
      <div style={{ height: "1px", width: "150px" }}></div>
      <div style={{ height: "1px", width: "150px" }}></div>
      <div style={{ height: "1px", width: "150px" }}></div>
    </>
  );
}

export default DetailsHouseCharacteristics;
