import React from "react";
import Hero from "./hero/Hero";
import Suggestions from "./suggestions/Suggestions";
import MyFooter from "../footer/MyFooter";
import NavMenu from "../navMenu/NavMenu";

function Home({ houses, i18n }) {
  return (
    <div>
      <NavMenu i18n={i18n}></NavMenu>
      <Hero></Hero>
      <Suggestions houses={houses}></Suggestions>
      <MyFooter></MyFooter>
    </div>
  );
}

export default Home;
