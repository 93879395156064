import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function AdvanceFilterFilter({ open, setOpen, searchHouse }) {
  const [district, setDistrict] = useState("0");
  const [county, setCounty] = useState("0");
  const [propertyType, setPropertyType] = useState("0");
  const [typology, setTypology] = useState("0");
  const [objective, setObjective] = useState("0");
  const [condition, setCondition] = useState("0");
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);

  return (
    <div
      className="_collapseAdvanceFilter"
      style={{
        minHeight: "200px",
        backgroundColor: "#F5F5F5",
      }}
    >
      <h2 className="text-center pt-2">Filtros Avançados</h2>
      <form className=" m-4">
        <div className="d-flex flex-fill flex-wrap justify-content-around">
          <div
            className="form-group flex-fill m-0 mx-2"
            style={{ width: "300px" }}
          >
            <label className="m-0">Preço Minimo:</label>
            <input
              type="number"
              className="form-control m-0"
              id="minprice"
              placeholder="Preço minimo..."
              value={minPrice}
              onChange={(e) => {
                setMinPrice(e.target.value);
              }}
              autocomplete="off"
            />
          </div>
          <div
            className="form-group flex-fill m-0 mx-2"
            style={{ width: "300px" }}
          >
            <label className="m-0">Preço Máximo:</label>
            <input
              type="number"
              className="form-control m-0"
              id="maxprice"
              placeholder="Preço maximo..."
              value={maxPrice}
              onChange={(e) => {
                setMaxPrice(e.target.value);
              }}
              autocomplete="off"
            />
          </div>
          <div
            className="form-group flex-fill m-0 mx-2"
            style={{ width: "300px" }}
          >
            <label className="m-0">Concelho:</label>
            <select
              value={county}
              onChange={(e) => {
                setCounty(e.target.value);
              }}
              className="custom-select"
            >
              <option value="0">Outro</option>
              <option value="1">Coimbra</option>
              <option value="2">Lousã</option>
              <option value="3">Miranda</option>
              <option value="4">Gois</option>
              <option value="5">Vila Nova de Poiares</option>
              <option value="6">Arganil</option>
              <option value="7">Oliveira do Hospital</option>
            </select>
          </div>
          <div
            className="form-group flex-fill m-0 mx-2"
            style={{ width: "300px" }}
          >
            <label className="m-0">Distrito:</label>
            <select
              value={district}
              onChange={(e) => {
                setDistrict(e.target.value);
              }}
              className="custom-select"
            >
              <option value="0">Outro</option>
              <option value="1">Coimbra</option>
            </select>
          </div>
          <div
            className="form-group flex-fill m-0 mx-2"
            style={{ width: "300px" }}
          >
            <label className="m-0">Tipo:</label>
            <select
              value={propertyType}
              onChange={(e) => {
                setPropertyType(e.target.value);
              }}
              className="custom-select"
            >
              <option value="0">Tipo...</option>
              <option value="1">Apartamento</option>
              <option value="2">Escritório</option>
              <option value="3">Imóveis com Negócio</option>
              <option value="4">Loja</option>
              <option value="5">Moradia</option>
              <option value="6">Prédio</option>
              <option value="7">Quarto</option>
              <option value="8">Quintas e Herdades</option>
              <option value="9">Terreno</option>
            </select>
          </div>

          <div
            className="form-group flex-fill m-0 mx-2"
            style={{ width: "300px" }}
          >
            <label className="m-0">Nº Quartos:</label>
            <select
              value={typology}
              onChange={(e) => {
                setTypology(e.target.value);
              }}
              className="custom-select"
            >
              <option value="0">Nº Quartos...</option>
              <option value="1">0 Quartos</option>
              <option value="2">1 Quarto</option>
              <option value="3">2 Quartos</option>
              <option value="4">3 Quartos</option>
              <option value="5">4 Quartos</option>
              <option value="6">5 Quartos</option>
              <option value="7">6 Quartos</option>
              <option value="8">7 Quartos</option>
              <option value="9">8 Quartos</option>
              <option value="10">9 Quartos</option>
              <option value="11">10 Quartos +</option>
            </select>
          </div>

          <div
            className="form-group flex-fill m-0 mx-2"
            style={{ width: "300px" }}
          >
            <label className="m-0">Objectivo:</label>
            <select
              value={objective}
              onChange={(e) => {
                setObjective(e.target.value);
              }}
              className="custom-select"
            >
              <option value="0">Objectivo...</option>
              <option value="1">Comprar</option>
              <option value="2">Arrendar</option>
              <option value="3">Trespasse</option>
            </select>
          </div>
          <div
            className="form-group flex-fill m-0 mx-2"
            style={{ width: "300px" }}
          >
            <label className="m-0">Estado:</label>
            <select
              value={condition}
              onChange={(e) => {
                setCondition(e.target.value);
              }}
              className="custom-select"
            >
              <option value="0">Estado...</option>
              <option value="1">Novo</option>
              <option value="2">Usado </option>
              <option value="3">Em Construção </option>
              <option value="4">Para Remodelar</option>
              <option value="5">Remodelado</option>
            </select>
          </div>
          <div
            className="form-group flex-fill m-0 mx-2"
            style={{ height: "5px", width: "300px" }}
          ></div>
          <div
            className="form-group flex-fill m-0 mx-2"
            style={{ height: "5px", width: "300px" }}
          ></div>
          <div
            className="form-group flex-fill m-0 mx-2"
            style={{ height: "5px", width: "300px" }}
          ></div>
          <div
            className="form-group flex-fill m-0 mx-2"
            style={{ height: "5px", width: "300px" }}
          ></div>
        </div>

        <div className="d-flex justify-content-center pb-4">
          <Button
            onClick={() => setOpen(false)}
            aria-controls="example-collapse-text"
            aria-expanded={open}
            className="btn btn-dark mx-1"
            style={{ minWidth: "100px" }}
          >
            Fechar
          </Button>
          <button
            className="btn btn-dark mx-1"
            onClick={(e) => {
              e.preventDefault();
              setOpen(false);
              searchHouse(
                objective,
                typology,
                propertyType,
                district,
                county,
                condition,
                minPrice,
                maxPrice
              );
            }}
          >
            Pesquisar
          </button>
        </div>
      </form>
    </div>
  );
}

export default AdvanceFilterFilter;
