import React from "react";
import Logo from "../../resources/img/logo-nobg2.png";
import FaceBookIcon from "../../resources/img/facebook-icon.png";
import { TelephoneFill } from "react-bootstrap-icons";

function MyFooter({ index }) {
  const date = new Date().getFullYear();
  return (
    <div
      key={index}
      style={{
        minHeight: "10vh",
        minWidth: "50vw",
        backgroundColor: "black",
        color: "white",
      }}
      className="d-flex justify-content-md-between justify-content-sm-center flex-wrap pb-2"
    >
      <div className="flex-fill mx-4 mt-3">
        <div className="d-flex justify-content-md-start justify-content-center">
          <img
            src={Logo}
            alt="Logo"
            style={{ width: "100px" }}
            className="mx-5"
          />
        </div>
        <p className="text-md-left text-center m-0 p-0">
          © {date} Imobiliária Xisto Village. Todos os direitos reservados.
        </p>
      </div>
      <div className="flex-fill mx-4 mt-3">
        <h3 className="text-md-right text-center" style={{ fontSize: "20px" }}>
          <TelephoneFill style={{ height: "20px" }} /> 912132662
        </h3>
        <h4 className="text-md-right text-center" style={{ fontSize: "20px" }}>
          xistovillage@gmail.com
        </h4>
        <div className="d-flex justify-content-md-end justify-content-center">
          <a href="http://www.facebook.com/xistovillage" target="_blank">
            <img
              src={FaceBookIcon}
              alt="Facebook"
              style={{ width: "30px" }}
              className="mx-5"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default MyFooter;
