import React from "react";
import Imgg from "../../../resources/img/interior-room.jpeg";
import { useTranslation } from "react-i18next";

function VenderCasaHero() {
  const { t, i18n } = useTranslation();
  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center m-auto"
      style={{
        height: "50vh",
        width: "100%",
        backgroundImage: "url(" + Imgg + ")",
        backgroundSize: "cover",
        backgroundPosition: "calc(100%) calc(40%)",
      }}
    >
      <h1 style={{ color: "white", textAlign: "center" }} className="mb-4">
        {t("sellhouse_hero")}
      </h1>
    </div>
  );
}

export default VenderCasaHero;
