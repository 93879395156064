import React, { useState, useEffect } from "react";
import i18n from "../i18n";
import { BrowserRouter as Router, Route } from "react-router-dom";
import NavMenu from "./navMenu/NavMenu";
import Contacts from "./contacts/Contacts";
import Admin from "./adminis/Adminis";
import Home from "./../components/home/Home";
import firebase from "./../firebase/index";
import MyFooter from "./footer/MyFooter";
import Details from "./details/Details";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Filter from "./filter/Filter";
import About from "./about/About";
import VenderCasa from "./venderCasa/VenderCasa";

function App() {
  const [houses, setHouses] = useState([]);

  useEffect(() => {
    getHouses();
  }, []);

  const getHouses = () => {
    firebase.db
      .collection("houses")
      .where("active", "==", true)
      .orderBy("highlight", "desc")
      .limit(8)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setHouses((prev) => [...prev, doc.data()]);
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const sendHouse = () => {
    firebase.db
      .collection("houses")
      .add({
        Condition: "",
        Country: "Portugal",
        Decription:
          "<ul><li>yyyy y yy y yyyyy yy </li><li>xxx x xx x x </li></ul>",
        District: "Coimbra",
        Energy: "C",
        Gross_Area: 200,
        N_Bathrooms: 3,
        N_Rooms: 4,
        Name: "APARTAMENTO T5",
        Objective: "Arrendamento",
        Parish: "Lousã",
        Price: "45 000",
        Ref: 2,
        Status: "Sob Consulta",
        Type: "Casa",
        Useful_Area: 150,
        Year_Construction: "",
        Zone: "",
      })
      .then((documentReference) => {
        //console.log("document reference ID", documentReference.id);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    <>
      <script src="https://www.gstatic.com/firebasejs/7.22.0/firebase-app.js"></script>
      <Router>
        <Route
          exact
          path="/"
          render={(props) => <Home houses={houses} i18n={i18n} />}
        ></Route>
        <Route
          path="/details/:ref"
          render={(props) => <Details firebase={firebase} />}
        />
        <Route path="/filter" component={Filter}></Route>
        <Route path="/contacts" component={Contacts}></Route>
        <Route path="/quemsomos" component={About}></Route>
        {/* <Route path="/vendercasa" component={VenderCasa}></Route> */}
        <Route
          exact
          path="/vendercasa"
          render={(props) => <VenderCasa i18n={i18n} />}
        ></Route>
        <Route
          path="/adminis"
          render={(props) => <Admin firebase={firebase} />}
        ></Route>
      </Router>
    </>
  );
}

export default App;
