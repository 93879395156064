import firebase from "firebase";
import "firebase/firestore";
import "firebase/auth";

firebase.initializeApp({
  apiKey: "AIzaSyA269IJ9eiqBtGEXWa4y2iJfxwC_tpfwBg",
  authDomain: "xisto-village-imo.firebaseapp.com",
  databaseURL: "https://xisto-village-imo.firebaseio.com",
  projectId: "xisto-village-imo",
  storageBucket: "xisto-village-imo.appspot.com",
  messagingSenderId: "198912918723",
  appId: "1:198912918723:web:901a3372c42b4c322fce76",
});

let db = firebase.firestore();
let auth = firebase.auth();

export default {
  firebase,
  db,
  auth,
};
